import React, { useState, useCallback } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { store } from "./redux/store"
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {IntlProvider} from "react-intl";
import MESSAGES from "./context/translations/messages";
import ErrorBoundary from './utils/errorBoundary';

const Index = () => {
    const [language, setLanguage] = useState('EN');

    const changeLanguage = useCallback(lang => {
        setLanguage(lang)
    }, []);

    const THEME = createTheme({
        typography: {
            "fontFamily": "HyundaiSansText",
        }
    });

    return (
      <IntlProvider locale={language} messages={MESSAGES[language]}>
        <ThemeProvider theme={THEME}>
          <Provider store={store}>
            <ErrorBoundary>
              <App language={language} changeLanguage={changeLanguage} />
            </ErrorBoundary>
          </Provider>
        </ThemeProvider>
      </IntlProvider>
    );
}

const rootElement = document.getElementById("root");
ReactDOM.render(<Index />, rootElement)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
