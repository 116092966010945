import { useEffect, useState } from "react";
import { getZipcode, getActiveTrim } from "../redux/selectors";
import { useDispatch, useSelector } from "react-redux";
import {
  useGetVehicleQuery,
  useGetLocationQuery,
} from "../redux/services/unifiedAPIQuery";
import {
  updateZipcode,
  updateElectricityPrice,
  updateFuelPrice,
} from "../redux/Slices/userSlice";
import { FormatAsTwoDecimals } from "./Format";

const queryString = require("query-string");

export default function useGetData() {
  const [data, setData] = useState({
    activeTrim: null,
    vehicleData: null,
    locationData: null,
  });
  const [skip, setSkip] = useState(true);
  const zipcode = useSelector(getZipcode);
  const activeTrim = useSelector(getActiveTrim);
  const dispatch = useDispatch();

  useEffect(() => {
    const query = queryString.parse(window.location.search);

    if (query?.zipcode) {
      dispatch(updateZipcode(query?.zipcode));
    }
  }, [dispatch]);

  const {
    status: vehicleStatus,
    data: vehicleData,
    error: vehicleError,
    isLoading: vehicleLoading,
  } = useGetVehicleQuery(
    {
      handle: activeTrim,
      postcode: zipcode,
    },
    { skip }
  );

  const {
    status: locationStatus,
    data: locationData,
    error: locationError,
    isLoading: locationLoading,
  } = useGetLocationQuery({
    postcode: zipcode,
  });

   const errors = [];
   if (vehicleError) errors.push(vehicleError);
   if (locationError) errors.push(locationError);


  useEffect(() => {
    if (vehicleError || locationError) {
      dispatch(updateZipcode(90210));
    }
  }, [vehicleError, locationError, dispatch]);

  useEffect(() => {
    if (locationData) {
      const electricityPrice = FormatAsTwoDecimals(
        locationData.location.regional_fuel_cost[0].electricity / 100
      );
      const fuelPrice = FormatAsTwoDecimals(
        locationData.location.regional_fuel_cost[0].gasoline
      );
      dispatch(updateElectricityPrice(electricityPrice));
      dispatch(updateFuelPrice(fuelPrice));
    }
  }, [locationData, dispatch]);

  useEffect(() => {
    if (vehicleStatus !== "fulfilled" || locationStatus !== "fulfilled") return;
    setData({ activeTrim, vehicleData, locationData });
  }, [activeTrim, vehicleStatus, vehicleData, locationStatus, locationData]);

  return {
    isLoading: vehicleLoading || locationLoading,
    isError: Boolean(vehicleError) || Boolean(locationError),
    errors: errors, 
    setSkip,
    ...data,
  };
}
